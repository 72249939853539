/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "../node_modules/ngx-spinner/animations/ball-clip-rotate.css";

/* Provide sufficient contrast against white background */

//-------------------//
// START CUSTOM CONTAINER
//-------------------//
.custom-container,
.custom-container-fluid,
.custom-container-lg,
.custom-container-md,
.custom-container-sm,
.custom-container-xl,
.custom-container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1400px) {
  .custom-container,
  .custom-container-fluid,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container-xl,
  .custom-container-xxl {
    max-width: 1470px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .custom-container,
  .custom-container-fluid,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container-xl,
  .custom-container-xxl {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .custom-container,
  .custom-container-fluid,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container-xl,
  .custom-container-xxl {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .custom-container,
  .custom-container-fluid,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container-xl,
  .custom-container-xxl {
    max-width: 720px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .custom-container,
  .custom-container-fluid,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container-xl,
  .custom-container-xxl {
    max-width: 540px;
  }
}

//-------------------//
// END CUSTOM CONTAINER
//-------------------//

a {
  color: #0366d6;
}

.ng2-pdf-viewer-container {
  overflow-x: auto !important;
  overflow-y: scroll !important;
  max-height: 45vh !important;
  max-width: 100% !important;
  display: block;
  -webkit-overflow-scrolling: touch;

  .pdfViewer .page {
    margin: 0 !important;
  }
}

.container-component {
  position: absolute;
  left: 10vh;
  max-width: 90vw;
}

.title-card {
  font-size: 1.6em;
  font-weight: 700;
}

:host::ng-deep {
  .form-control {
    padding: 0.25rem 0.75rem !important;
  }

  .p-dropdown .p-dropdown-label {
    padding: 6px 12px;
  }

  .p-dropdown-label.p-inputtext {
    padding: 0.75em !important;
  }

  .p-dropdown.p-component {
    width: 100% !important;
  }

  .p-element.p-inputwrapper {
    width: 100% !important;
  }

  .p-chips.p-component {
    width: 100% !important;
  }

  .p-inputtext.p-chips-multiple-container {
    width: 100% !important;
  }
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.full-size {
  width: 100% !important;
  height: 100% !important;
}

.flex-1 {
  flex-grow: 1;
}

html * {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

/* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
} */
.chip-mail {
  display: block;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.display-none {
  display: none;
}

//-------------------//
// START MARGINI
//-------------------//

//START MARGIN TOP
.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

//END MARGIN TOP

//START MARGIN BOTTOM
.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

//END MARGIN BOTTOM

//START MARGIN RIGHT
.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

//END MARGIN RIGHT

//START MARGIN LEFT
.ml-0_5 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ellipsis * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.p-button.p-button-icon-only {
  width: 2.3rem;
  // height: 2.3rem;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem !important;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.1rem 1.25rem !important;
}

.p-column-resizer {
  background-color: #ddd;
  width: 0.1rem !important;
}

//END MARGIN LEFT

//-------------------//
// END MARGINI
//-------------------//

.p-calendar .p-datepicker {
  top: 40px !important;
}

.align-center {
  text-align: center;
}
